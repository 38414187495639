






















































































































































































































































































































































































































.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
