.btn-box {
  padding: 50px 0 10px;
  display: flex;
  justify-content: center;
}
.btn-box button {
  padding: 12px 30px;
}
.btn-box .el-button.is-disabled,
.btn-box .el-button.is-disabled:focus,
.btn-box .el-button.is-disabled:hover {
  background: #a9a9a9;
  border-color: #a9a9a9;
  color: #fff;
}
